<template>
  <v-row no-gutters>
    <v-col md="3">
      <text-field
        v-model.trim="search"
        outlined
        maxlength="50"
        label="Search Project"
        placeholder="Type to search"
      />
    </v-col>
    <v-col class="d-flex justify-end align-center">
      <primary-button short @click="newProject = true">
        Add project
      </primary-button>
    </v-col>
    <v-col md="12">
      <data-table
        v-model="selectedRows"
        :search="search"
        show-select
        :items="projects"
        :headers="headers"
        :hide-footer="true"
        :items-per-page="20"
        :disable-pagination="loading"
        :server-items-length="projectsMeta.total"
        :page="projectsMeta.current_page"
        :loading="loading"
        :custom-columns="['item.name', 'item.members', 'item.trackable']"
      >
        <template v-slot:custom-render-item.name="{ item }">
          <div class="d-flex align-center">
            <v-icon
              x-small
              class="dot-icon mr-2"
              :color="item.color_hex"
            >
              fiber_manual_record
            </v-icon>
            {{ item.name }}
          </div>
        </template>
        <template v-slot:custom-render-item.trackable="{ item }">
          <div class="d-flex align-center">
            <v-icon
              x-small
              class="dot-icon mr-2"
              :class="`${item.trackable}-dot`"
            >
              fiber_manual_record
            </v-icon>
          </div>
        </template>
        <template v-slot:custom-render-item.members="{ item }">
          <div class="d-flex align-center">
            <img
              v-for="(src, index) of item.members.slice(0, item.count)"
              :key="index"
              class="member-img"
              :src="src.image"
              :alt="src.name"
              :title="src.name"
            >
            <div
              v-if="item.members.length > 3 && item.count <= 3"
              class="member-img more"
              @click="showAllMembers(item)"
            >
              +{{ item.members.length - 3 }}
            </div>
          </div>
        </template>
        <template v-slot:actions-menu="{ item }">
          <data-table-actions>
            <data-table-action-item
              icon="drive_file_rename_outline"
              @click="getSelectedProject(item)"
            />
            <!--  <data-table-action-item

                @click="foo(item)"
              >
                <action />
              </data-table-action-item>-->
          </data-table-actions>
        </template>
        <template v-slot:footer>
          <div v-show="projects.length" class="v-data-footer">
            <base-pagination
              v-model="params.page"
              :pagination-meta="projectsMeta"
              :has-pagination-pages="false"
              :has-counter-items-on-page="true"
              @change-page="changePaginationPage"
            />
          </div>
        </template>
      </data-table>
    </v-col>
    <table-checked-bar v-model="snackbar">
      <v-row no-gutters>
        <!--    <v-col>
          <secondary-button
            @click="confirmStatus = true"
            small>
            Delete {{ selectedRows.length || '' }}
          </secondary-button>
        </v-col>-->
        <v-col class="d-flex justify-end mr-3">
          <secondary-button
            small
            @click="confirmStatus = true"
          >
            Archive {{ selectedRows.length || '' }}
          </secondary-button>
        </v-col>
        <v-col>
          <text-button
            small
            @click="resetSelectedRows"
          >
            Cancel
          </text-button>
        </v-col>
      </v-row>
    </table-checked-bar>
    <custom-dialog
      :key="refreshDialog"
      v-model="newProject"
      :loading="save"
      title="Create Project"
      @save="saveNewProject"
      @input="closeCreateDialog"
    >
      <validation-observer ref="observer">
        <v-form>
          <v-row>
            <v-col md="8" class="py-0">
              <validation-provider
                v-slot="{ errors }"
                name="title"
                rules="required|max:50|min:3"
                :custom-messages="{min: 'Min 3 characters allowed'}"
              >
                <text-field
                  v-model="formData.name"
                  label="Project Title"
                  :error-messages="errors"
                  outlined
                />
              </validation-provider>
            </v-col>
            <v-col md="4" class="py-0">
              <validation-provider
                v-slot="{ errors }"
                name="color"
                rules="required"
              >
                <color-select
                  v-model="formData.color"
                  label="Project Color"
                  :items="colors"
                  arrow-class="black-arrow"
                  outside
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col md="12" class="py-0">
              <autocomplete
                v-model="formData.admins"
                label="Admins"
                multiple
                placeholder="Type member's name or email@"
                :hide-no-data="admins.noResult"
                item-text="name"
                item-value="member_card_id"
                return-object
                :loading="admins.loading"
                :items="admins.items"
                :search-input="searchInput"
                :filter="adminFilter"
                @change="selectedMember($event, 'admin')"
                @click:close="removeMember($event)"
                @update-search-input="searchMembers($event, 'admin')"
              />
            </v-col>
            <v-col md="12" class="py-0">
              <autocomplete
                v-model="formData.managers"
                label="Managers"
                item-text="name"
                item-value="member_card_id"
                placeholder="Type member's name or email@"
                :hide-no-data="managers.noResult"
                :loading="managers.loading"
                multiple
                return-object
                :items="managers.items"
                :search-input="searchInput"
                :filter="adminFilter"
                @change="selectedMember($event, 'manager')"
                @update-search-input="searchMembers($event, 'manager')"
                @click:close="removeMember($event)"
              />
            </v-col>
            <v-col md="12" class="py-0">
              <autocomplete
                v-model="formData.users"
                label="Users"
                item-text="name"
                item-value="member_card_id"
                placeholder="Type member's name or email@"
                :hide-no-data="users.noResult"
                :loading="users.loading"
                multiple
                return-object
                :items="users.items"
                :search-input="searchInput"
                :filter="adminFilter"
                @change="selectedMember($event, 'user')"
                @update-search-input="searchMembers($event, 'user')"
                @click:close="removeMember($event)"
              />
            </v-col>
            <v-col md="12" class="py-0">
              <autocomplete
                v-model="formData.clients"
                label="Clients"
                item-text="name"
                item-value="member_card_id"
                placeholder="Type member's name or email@"
                :hide-no-data="clients.noResult"
                :loading="clients.loading"
                multiple
                return-object
                :items="clients.items"
                :search-input="searchInput"
                :filter="adminFilter"
                @change="selectedMember($event, 'client')"
                @update-search-input="searchMembers($event, 'client')"
                @click:close="removeMember($event)"
              />
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </custom-dialog>
    <custom-dialog
      v-model="editProject.status"
      :loading="save"
      :content-load="loadingModal"
      title="Edit Project"
      @save="saveEditedProject"
    >
      <validation-observer ref="observer">
        <v-form>
          <v-row>
            <v-col md="8" class="py-0">
              <validation-provider
                v-slot="{ errors }"
                name="edit-title"
                rules="required|max:50|min:3"
                :custom-messages="{min: 'Min 3 characters allowed'}"
              >
                <text-field
                  v-model="formData.name"
                  label="Project Title"
                  outlined
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col md="4" class="py-0">
              <validation-provider
                v-slot="{ errors }"
                name="title"
                rules="required"
              >
                <color-select
                  v-model="formData.color"
                  label="Project Color"
                  :items="colors"
                  arrow-class="black-arrow"
                  outside
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col md="12" class="py-0">
              <autocomplete
                v-model="formData.admins"
                label="Admins"
                multiple
                :hide-no-data="admins.noResult"
                placeholder="Type member's name or email@"
                item-text="name"
                item-value="member_card_id"
                return-object
                :loading="admins.loading"
                :items="admins.items"
                :search-input="searchInput"
                :filter="adminFilter"
                @change="selectedMember($event, 'admin')"
                @update-search-input="searchMembers($event, 'admin')"
                @click:close="removeMember($event)"
              />
            </v-col>
            <v-col md="12" class="py-0">
              <autocomplete
                v-model="formData.managers"
                label="Managers"
                item-text="name"
                placeholder="Type member's name or email@"
                item-value="member_card_id"
                :hide-no-data="managers.noResult"
                :loading="managers.loading"
                return-object
                multiple
                :items="managers.items"
                :search-input="searchInput"
                :filter="adminFilter"
                @change="selectedMember($event, 'manager')"
                @update-search-input="searchMembers($event, 'manager')"
                @click:close="removeMember($event)"
              />
            </v-col>
            <v-col md="12" class="py-0">
              <autocomplete
                v-model="formData.users"
                label="Users"
                item-text="name"
                item-value="member_card_id"
                placeholder="Type member's name or email@"
                :hide-no-data="users.noResult"
                :loading="users.loading"
                return-object
                multiple
                :items="users.items"
                :search-input="searchInput"
                :filter="adminFilter"
                @change="selectedMember($event, 'user')"
                @update-search-input="searchMembers($event, 'user')"
                @click:close="removeMember($event)"
              />
            </v-col>
            <v-col md="12" class="py-0">
              <autocomplete
                v-model="formData.clients"
                label="Clients"
                item-text="name"
                item-value="member_card_id"
                placeholder="Type member's name or email@"
                :hide-no-data="clients.noResult"
                :loading="clients.loading"
                return-object
                multiple
                :items="clients.items"
                :search-input="searchInput"
                :filter="adminFilter"
                @change="selectedMember($event, 'client')"
                @update-search-input="searchMembers($event, 'client')"
                @click:close="removeMember($event)"
              />
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </custom-dialog>
    <custom-dialog
      v-model="showAllMembersStatus"
      title="Members"
      :project-title="selectedProjectItem.name"
      hide-actions
    >
      <div class="project-member-content">
        <v-icon
          x-small
          class="dot-icon mr-2"
          :class="`${selectedProjectItem.color_name}-dot`"
        >
          fiber_manual_record
        </v-icon>
        {{ selectedProjectItem.name }}
      </div>
      <v-row class="project-member-row">
        <v-col
          v-for="item of projectMembersList"
          :key="item.id"
          cols="12"
        >
          <div class="member-img project-member-wrap">
            <img :src="item.image" :alt="item.name">
            <span>
              {{ item.name }}
            </span>
          </div>
        </v-col>
        <v-col cols="12">
          <primary-button
            v-if="projectMemberParams.page !== getProjectMembersMeta.last_page"
            :loading="memberLoading"
            class="project-member-btn"
            @click="showMoreMembers"
          >
            Load more
          </primary-button>
        </v-col>
      </v-row>
    </custom-dialog>
    <confirm-modal
      v-model="confirmStatus"
      title="Archive Project"
      @save="archiveProject"
    >
      Are you sure you want to archive this project?
    </confirm-modal>
  </v-row>
</template>

<script>
import TextField from '@/components/ui/TextField/TextField';
import DataTable from '@/components/collections/DataTable/DataTable';
import { PROJECT_ACTIVE } from '@/views/Project/constants';
import DataTableActions from '@/components/collections/DataTableActions/DataTableActions';
import DataTableActionItem from '@/components/collections/DataTableActions/DataTableActionItem';
import PrimaryButton from '@/components/ui/Buttons/PrimaryButton';
import TableCheckedBar from '@/components/ui/Snackbars/TableCheckedBar';
import SecondaryButton from '@/components/ui/Buttons/SecondaryButton';
import TextButton from '@/components/ui/Buttons/TextButton';
import CustomDialog from '@/components/collections/Dialog/CustomDialog';
import ColorSelect from '@/components/collections/ColorSelect/ColorSelect';
import Autocomplete from '@/components/ui/Autocomplete/Autocomplete';
import BasePagination from '@/components/ui/Pagination/BasePagination';
import { apiCall } from '@/services/axios';
import { debounce, updateParams } from '@/helpers';
import ConfirmModal from '@/components/collections/Dialog/ConfirmModal';
import { mapGetters } from 'vuex';
import QueryParamsMixin from '@/mixins/queryParamsMixin';

export default {
  name: 'Active',
  components: {
    ConfirmModal,
    Autocomplete,
    ColorSelect,
    CustomDialog,
    TextButton,
    SecondaryButton,
    TableCheckedBar,
    PrimaryButton,
    DataTableActionItem,
    DataTableActions,
    DataTable,
    TextField,
    BasePagination,
  },
  mixins: [QueryParamsMixin],
  data() {
    return {
      search: null,
      searchInput: null,
      projects: [],
      headers: PROJECT_ACTIVE,
      snackbar: false,
      selectedRows: [],
      newProject: false,
      clients: {
        loading: false,
        items: [],
        noResult: true,
      },
      managers: {
        loading: false,
        items: [],
        noResult: true,
      },
      admins: {
        loading: false,
        items: [],
        noResult: true,
      },
      users: {
        loading: false,
        items: [],
        noResult: true,
      },
      formData: {
        name: '',
        color: '',
        admins: [],
        managers: [],
        users: [],
        clients: [],
      },
      params: {
        is_active: '1',
        orderBy: 'name',
        sortedBy: 'asc',
        page: 1,
      },
      loading: false,
      loadingModal: false,
      exceptionId: [],
      editProject: {
        status: false,
        id: '',
      },
      confirmStatus: false,
      save: false,
      selectedIds: {
        admin: [],
        manager: [],
        user: [],
        client: [],
      },
      selectedProject: null,
      showAllMembersStatus: false,
      selectedProjectItem: {
        color_name: null,
        name: null,
      },
      projectMemberParams: {
        page: 1,
      },
      memberLoading: false,
      projectMembersList: [],
      refreshDialog: 0,
    };
  },
  computed: {
    ...mapGetters({
      colors: 'getColors',
      projectsMeta: 'getProjectsMeta',
      getProjectMembers: 'getProjectMembers',
      getProjectMembersMeta: 'getProjectMembersMeta',
    }),
    selectedWorkspaceId() {
      return this.$store.getters.getSelectedWorkspace.id;
    },
  },
  watch: {
    selectedRows: {
      deep: true,
      handler(val) {
        this.snackbar = !!val.length;
      },
    },
    showAllMembersStatus(val) {
      if (!val) {
        this.projectMemberParams.page = 1;
        this.projectMembersList = [];
      }
    },
    search(val) {
      if (val === undefined || val === null) return;

      if (val.length > 50) {
        this.search = val.slice(0, 50);
      }

      this.useDebounce(val);
    },
    newProject(val) {
      if (val) {
        this.resetFormData();
        this.formData.color = 1;
      } else {
        this.exceptionId = [];
      }

      this.exceptionId = [];
    },
    editProject: {
      deep: true,
      handler(val) {
        if (!val.status) {
          this.exceptionId = [];
        }
      },
    },
    formData: {
      deep: true,
      handler(val) {
        if (val) {
          this.searchInput = null;
        }
      },
    },
    params: {
      handler(data) {
        this.setQueryParams({
          search: data.search,
          page: data.page,
        });
      },
      deep: true,
    },
  },
  created() {
    this.useDebounce = debounce((searchValue) => {
      this.handleSearch(searchValue);
    }, 600);
  },
  async mounted() {
    if (Object.keys(this.$route.query).length > 0) {
      this.updateDataByQueryParams(this.$route.query);
      this.search = this.$route.query.search;
    }

    if (!this.$route.query?.search) {
      await this.initialLoad();
    }

    this.$store.subscribe((mutation) => {
      if (mutation.type === 'updateSelectedWorkspace') {
        this.params = {
          is_active: '1',
          orderBy: 'name',
          sortedBy: 'asc',
          page: 1,
        };

        this.initialLoad();
      }
    });
  },
  methods: {
    handleSearch(searchValue) {
      this.params = updateParams(searchValue, this.params);
      this.initialLoad();
    },

    async initialLoad() {
      this.loading = true;
      await this.$store.dispatch('GET_ACTIVE_PROJECTS', {
        id: this.selectedWorkspaceId,
        params: this.params,
      })
        .then(() => {
          this.projects = this.$store.getters.getProjectsWithCount;
          this.loading = false;
        });
    },
    async showAllMembers(val) {
      this.selectedProjectItem = val;
      this.showAllMembersStatus = true;
      await this.$store.dispatch('GET_PROJECT_MEMBERS', {
        id: this.selectedWorkspaceId,
        project: val.id,
        params: this.projectMemberParams,
      })
        .then(() => {
          this.projectMembersList = this.$store.getters.getProjectMembers;
        });
    },
    async showMoreMembers() {
      this.memberLoading = true;
      this.projectMemberParams.page += 1;
      await this.$store.dispatch('GET_PROJECT_MEMBERS', {
        id: this.selectedWorkspaceId,
        project: this.selectedProjectItem.id,
        params: this.projectMemberParams,
      })
        .then(() => {
          const arr = this.projectMembersList;
          arr.push(...this.$store.getters.getProjectMembers);
          this.projectMembersList = arr;
          this.memberLoading = false;
        });
    },
    changePaginationPage(page) {
      this.params.page = page;
      this.loading = true;
      this.$store.dispatch('GET_ACTIVE_PROJECTS', {
        id: this.selectedWorkspaceId,
        params: this.params,
      })
        .then(() => {
          this.projects = this.$store.getters.getProjectsWithCount;
          this.loading = false;
        });
    },
    async getSelectedProject(val) {
      this.editProject.status = true;
      this.selectedProject = val.id;
      this.loadingModal = true;

      try {
        await apiCall('get', `workspaces/${this.selectedWorkspaceId}/projects/${val.id}`)
          .then((res) => {
            this.formData = {
              name: res.data.data.name,
              color: res.data.data.color_id,
              admins: this.filterRole(res.data.data.members, 'project-admin'),
              managers: this.filterRole(res.data.data.members, 'project-manager'),
              users: this.filterRole(res.data.data.members, 'project-user'),
              clients: this.filterRole(res.data.data.members, 'project-client'),
            };
            this.admins.items = this.filterRole(res.data.data.members, 'project-admin')
              .concat(this.formData.admins);
            this.managers.items = this.filterRole(res.data.data.members, 'project-manager')
              .concat(this.formData.managers);
            this.users.items = this.filterRole(res.data.data.members, 'project-user')
              .concat(this.formData.users);
            this.clients.items = this.filterRole(res.data.data.members, 'project-client')
              .concat(this.formData.clients);

            this.exceptionId = res.data.data.members.map((item) => item.member_card_id);
          });
      } catch (e) {
        console.log(e);
      }

      this.loadingModal = false;
    },
    saveNewProject() {
      const observerRef = this.$refs.observer;
      observerRef.validate().then((isValid) => {
        if (isValid) {
          this.save = true;

          const data = {
            name: this.formData.name,
            color_id: this.formData.color || 1,
            member_cards: [
              ...[this.formData.admins],
              ...[this.formData.managers],
              ...[this.formData.users],
              ...[this.formData.clients],
            ].flat(),
          };
          this.$store.dispatch('CREATE_NEW_PROJECT', {
            id: this.selectedWorkspaceId,
            project: data,
            params: this.params,
          }).then(async () => {
            await this.initialLoad();
            this.save = false;
            this.newProject = false;
            this.exceptionId = [];
            this.resetFormData();
            observerRef.reset();
            this.$forceUpdate();
          });
        }
      });
    },
    async searchMembers(val, type) {
      console.log(val, 'val');
      console.log(type, 'type');

      if (val === null) {
        this.searchInput = val;
      }

      if (val !== null) {
        this.searchInput = val;
      }

      const searchData = {
        is_active: true,
        search: val,
        except_ids: this.exceptionId,
      };

      if (val) {
        if (val.length >= 2) {
          await this.getMembersList(searchData, type);
        }
      }
    },
    async getMembersList(data, type) {
      try {
        switch (type) {
          case 'admin':
            this.admins.loading = true;
            break;
          case 'manager':
            this.managers.loading = true;
            break;
          case 'user':
            this.users.loading = true;
            break;
          case 'client':
            this.clients.loading = true;
            break;
          default:
            console.log('error');
        }

        await apiCall('post', `workspaces/${this.selectedWorkspaceId}/search-members`, data)
          .then((res) => {
            switch (type) {
              case 'admin':
                this.admins.items = this.setRole(res.data.data, 'project-admin').concat(this.formData.admins);
                this.admins.noResult = !!res.data.data.length;
                this.admins.loading = false;
                break;
              case 'manager':
                this.managers.items = this.setRole(res.data.data, 'project-manager').concat(this.formData.managers);
                this.managers.loading = false;
                this.managers.noResult = !!res.data.data.length;
                break;
              case 'user':
                this.users.items = this.setRole(res.data.data, 'project-user').concat(this.formData.users);
                this.users.loading = false;
                this.users.noResult = !!res.data.data.length;
                break;
              case 'client':
                this.clients.items = this.setRole(res.data.data, 'project-client').concat(this.formData.clients);
                this.clients.loading = false;
                this.clients.noResult = !!res.data.data.length;
                break;
              default:
                console.log('error');
            }
          });
      } catch (e) {
        console.log(e);
      }
    },
    selectedMember(val, type) {
      const idArr = val.map((item) => item.member_card_id);

      if (this.selectedIds[type].length > idArr.length) {
        const arr = this.selectedIds[type].filter((item) => !idArr.includes(item));
        this.exceptionId = this.exceptionId.filter((item) => item !== arr[0]);
      }

      this.selectedIds[type] = JSON.parse(JSON.stringify(idArr));

      const arr2 = [...this.exceptionId];
      const newArr = arr2.concat(idArr);
      this.exceptionId = [...new Set(newArr)];
    },
    archiveProject() {
      const ids = this.selectedRows.map((item) => item.id);
      this.$store.dispatch('ARCHIVE_PROJECTS', {
        id: this.selectedWorkspaceId,
        projects: { project_ids: ids },
      }).then(async () => {
        this.resetSelectedRows();
        this.confirmStatus = false;
        await this.initialLoad();
      });
    },
    deleteProject() {
      const ids = this.selectedRows.map((item) => item.id);
      this.$store.dispatch('DELETE_PROJECTS', {
        id: this.selectedWorkspaceId,
        projects: { project_ids: ids },
      }).then(async () => {
        this.resetSelectedRows();
        this.confirmStatus = false;
        await this.initialLoad();
      });
    },
    async saveEditedProject() {
      const observerRef = this.$refs.observer;
      observerRef.validate().then((isValid) => {
        if (isValid) {
          this.save = true;

          const data = {
            name: this.formData.name,
            color_id: this.formData.color,
            member_cards: [
              ...this.setRole(this.formData.admins, 'project-admin'),
              ...this.setRole(this.formData.managers, 'project-manager'),
              ...this.setRole(this.formData.users, 'project-user'),
              ...this.setRole(this.formData.clients, 'project-client'),
            ].flat(),
          };
          this.$store.dispatch('EDIT_PROJECT', {
            id: this.selectedWorkspaceId,
            selectedProject: this.selectedProject,
            project: data,
            params: this.params,
          }).then(async () => {
            await this.initialLoad();
            this.save = false;
            this.editProject.status = false;
            this.exceptionId = [];
            observerRef.reset();
          });
        }
      });
    },
    setRole(array, role) {
      const arr = array.map((item) => {
        this.$set(item, 'role_alias', role);

        return item;
      });

      return arr;
    },
    removeMember(val) {
      const arr = this.exceptionId.filter((item) => item !== val.member_card_id);
      this.exceptionId = arr;
    },
    filterRole(array, val) {
      const arr = array.filter((item) => item.role.alias === val);

      return arr;
    },
    resetFormData() {
      this.formData = {
        name: '',
        color: '',
        admins: [],
        managers: [],
        users: [],
        clients: [],
      };
    },
    resetSelectedRows() {
      this.selectedRows = [];
    },
    closeCreateDialog(val) {
      if (!val) {
        this.refreshDialog++;
      }
    },
    adminFilter(item, queryText) {
      const searchText = queryText.toLocaleLowerCase();
      const email = item.email ? item.email.toLocaleLowerCase() : '';
      const name = item.name.toLocaleLowerCase();
      const includeEmail = email.indexOf(searchText) > -1;
      const includeName = name.indexOf(searchText) > -1;

      return includeName || includeEmail;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .member-img {
    width: 30px;
    height: 30px;
    border: 2px solid $white;
    border-radius: 11px;

    &:not(:first-child) {
      margin-left: -10px;
    }

    &.more {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $primary;
      color: $white;
      font-size: $type-xs;
      cursor: pointer;
    }
  }
  .project-member-wrap {
    display: flex;
    align-items: center;
    img {
      width: 30px;
      height: 30px;
      border: 2px solid $white;
      border-radius: 11px;
      margin-right: 8px;
    }
  }
  .project-member-content {
    margin-top: -40px;
  }
  .project-member-row {
    margin-top: 33px;
    max-height: 375px;
    /*overflow-y: auto;*/
  }
  .project-member-btn {
    margin: 0 auto;
    display: flex;
  }

  .custom-pagination {}
</style>
