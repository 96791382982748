import { render, staticRenderFns } from "./Active.vue?vue&type=template&id=78b3840a&scoped=true&"
import script from "./Active.vue?vue&type=script&lang=js&"
export * from "./Active.vue?vue&type=script&lang=js&"
import style0 from "./Active.vue?vue&type=style&index=0&id=78b3840a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78b3840a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VForm,VIcon,VRow})
